import React, { useState, useEffect } from "react";
import Header from "./Components/UI/Header";
import Hero from "./Components/UI/Hero";
import SingleProduct from "./Components/Product/SingleProduct";
import Slideout from "./Components/Cart/Slideout";
import { getWHHProducts, getHeroData, getProducts } from "./Api/products.api";
import { Helmet, HelmetProvider } from "react-helmet-async";
import localforage from "localforage";
import "react-lazy-load-image-component/src/effects/blur.css";
import Client from "shopify-buy";
import AgeVerify from "./Components/AgeVerify";

const client = Client.buildClient({
  domain: "parcellewhitelabel.myshopify.com",
  storefrontAccessToken: "e8a92037f58f07de10afaab38014a7d4",
});

const wHHProductList = [
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc0NDkwNzkxNTI4ODI=",
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc0NDkwNzY5NTc0MjY=",
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc0NDkwNzA5MjgxMTQ=",
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc0NDkwNjc0MjE5Mzg=",
];

export default function App() {
  const [openCart, setOpenCart] = useState(false);
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [heroData, setHeroData] = useState([]);
  const [loading, setLoading] = useState(false);

  const overlayOpen = openCart ? "hiddendiv overlay" : "hiddendiv";

  const showDialog = () => {
    const scrollY =
      document.documentElement.style.getPropertyValue("--scroll-y");
    const body = document.body;
    body.style.position = "fixed";
    body.style.top = `-${scrollY}`;
  };

  const closeDialog = () => {
    const body = document.body;
    const scrollY =
      document.documentElement.style.getPropertyValue("--scroll-y");
    body.style.position = "";
    body.style.top = `-${scrollY}`;
  };

  const addItemToCart = (addedItem) => {
    let UpdatedProduct = cart.map((item) =>
      item.id === addedItem.id && addedItem.secondTitle === item.secondTitle
        ? {
            ...item,
            quantity: parseInt(item.quantity) + parseInt(addedItem.quantity),
          }
        : item
    );
    let isPresent = cart.some(function (el) {
      return (
        el.id === addedItem.id &&
        addedItem.secondTitle === el.secondTitle &&
        addedItem.purchaseType === el.purchaseType
      );
    });
    const updatedCart = isPresent ? UpdatedProduct : [...cart, addedItem];
    setCart(updatedCart);
    localforage.setItem("cart", updatedCart);
  };

  // INIT FUNCTION
  useEffect(() => {
    loadProducts();
    loadHeroData();
  }, []);

  async function loadHeroData() {
    const newHeroData = await getHeroData();
    setHeroData(newHeroData);
  }

  async function handleCheckout() {
    setLoading(true);
    const lineItemsToAdd = cart.map((cartItem) => ({
      ...cartItem,
      variantId: parseInt(cartItem.shopifyVariantId),
      quantity: cartItem.quantity,
    }));

    let myUrl = "http://fulfillment.parcellewine.com/cart/";
    lineItemsToAdd.forEach((lili, index) => {
      console.log(lili);
      // TODO need Refersion ID
      myUrl += index === 0 ? "add?rfsn=5200441.928c57&" : "&";
      myUrl += `id[]=${
        lili?.purchaseType?.includes("subscribe")
          ? lili.variantId
          : lili.oneTimeVariantId
      }&quantity=${lili.quantity}`;
      myUrl += `&selling_plan=`;
      myUrl += lili?.secondTitle?.includes("monthly") ? `${4698734834}` : ""; // 4698734834 is the normal cost based monthly subscription
      // myUrl += `&properties%5Bcharge_interval_frequency%5D=1`;
      // myUrl += `&properties%5Bcharge_interval_unit_type%5D=Months`;
      // myUrl += `&properties%5Bshipping_interval_frequency%5D=1`;
      // myUrl += `&properties%5Bshipping_interval_unit_type%5D=Months`;
    });

    window.location = myUrl;
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }

  async function loadProducts() {
    await getWHHProducts();
    let cmsProducts = await getProducts();

    let mergedProducts = [];
    client.product.fetchAll(100).then((shopProds) => {
      // console.log(shopProds);
      const shopifyProducts = shopProds.filter((el) =>
        wHHProductList.includes(el.id)
      );
      cmsProducts?.forEach((el) => {
        const shopProd = shopifyProducts.filter(
          (item) => item.id === el.shopifygraphqlid
        );
        let myShopPrd = {
          id: shopProd[0]?.id,
          variants: shopProd[0]?.variants.map((el) => ({
            price: el?.price,
            id: el?.id,
            title: el?.title,
            sku: el?.sku,
            available: el?.available,
          })),
          variantId: shopProd[0]?.variants[0].id,
          availableForSale: shopProd[0]?.availableForSale,
        };
        mergedProducts.push({
          ...el,
          ...myShopPrd,
        });
      });

      console.log(mergedProducts, "mergedProducts");
      setProducts(mergedProducts);
    });
    localforage.getItem("cart", (_, value) => {
      if (value) setCart(value);
    });
  }

  function randomNum(min, max) {
    var n = [];
    for (var i = 0; i < 9; i++) {
      const myInt = Math.floor(Math.random() * (max - min + 1) + min);
      if (!n.includes(myInt) && n.length < 3) {
        n.push(myInt);
      }
    }
    return n;
  }

  return (
    <HelmetProvider>
      <div className="App  ">
        <Helmet>
          <title>Wine & Hip Hop | Parcelle Wine</title>
        </Helmet>
        <div
          className={overlayOpen}
          onClick={() => {
            setOpenCart(!openCart);
            openCart ? closeDialog() : showDialog();
          }}
        ></div>
        <AgeVerify />
        <Slideout
          {...{
            openCart,
            setOpenCart,
            showDialog,
            closeDialog,
            cart,
            setCart,
            handleCheckout,
            loading,
          }}
        />
        <Header
          {...{ openCart, setOpenCart, showDialog, closeDialog }}
          showBadge={cart.length}
        />
        <Hero heroData={heroData} />
        <div className=" product-section-wrapper container">
          <div className="product-grid-wrapper">
            {products &&
              products
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((product) => {
                  return (
                    <SingleProduct
                      key={product.id}
                      product={product}
                      {...{
                        openCart,
                        setOpenCart,
                        showDialog,
                        closeDialog,
                        addItemToCart,
                        cart,
                      }}
                      id={product.id}
                      title={product.productTitle}
                      smallText={product.subtitle}
                      subTitle={product.secondTitle}
                      description={product.productDescription}
                      featuredImg={product.featuredImage}
                      imgs={product.productImages}
                      price={product.price}
                      productDescription={product.productDescription}
                    />
                  );
                })}
          </div>
        </div>
      </div>
      <div className="footer_fixed segoe-font-title">
        <div className="footer__bottom-content footer__bottom-content--reverse">
          <div className="fulfilled_by">
            ©{new Date().getFullYear()}, FULFILLED BY PARCELLE WINE
          </div>

          <div className="footer-contact">
            Questions?{" "}
            <a href="mailto:thewineclub@parcellewine.com">
              thewineclub@parcellewine.com
            </a>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
