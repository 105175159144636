import { queryDatoCms, queryParcelleDb, queryShopify } from "./config";
import { GET_HERO, GET_PRODUCTS } from "./Queries/products.query";

export async function getProducts() {
  const answer = await queryDatoCms(GET_PRODUCTS)
    .then((result) => {
      return result?.data?.data?.allWhhproducts;
    })
    .catch(() => {
      return false;
    });
  return answer;
}

export async function getHeroData() {
  const answer = await queryDatoCms(GET_HERO)
    .then((result) => {
      // console.log(result);
      return result?.data?.data?.whhHero;
    })
    .catch(() => {
      return false;
    });
  return answer;
}

export async function getWHHProducts() {
  const answer = await queryShopify("w_l_parcelle/getWHHProducts")
    .then((result) => {
      return result?.data?.data?.products;
    })
    .catch(() => {
      return false;
    });
  return answer;
}
