import React from "react";
import CheckoutCart from "../Icons/CheckoutCart";
import WineHipHopLogo from "../../Assets/Css/imgs/wineHipHop.png";

const Header = ({ setOpenCart, openCart, showDialog, closeDialog, showBadge }) => {
  return (
    <div className="header-container">
      <div className="dwell-logo">
        <img src={WineHipHopLogo} />
      </div>

      <div className={`icon-wrapper `}>
        <ul className="nav-items">
          <li>
            <a href="https://wineandhiphop.com/podcasts">Podcast</a>
          </li>
          <li>
            <a href="https://wineandhiphop.com/videos">Videos</a>
          </li>
          <li>
            <a href="https://wineandhiphop.com/playlists-a">Playlists</a>
          </li>
          <li>
            <a href="https://wineandhiphop.com/about">About</a>
          </li>
        </ul>
        <div
          className="cart_icon"
          style={{ width: "40px", cursor: "pointer" }}
          onClick={() => {
            setOpenCart(!openCart);
          }}
        >
          {showBadge ? <div className="bag-badge"></div> : ""}
          <CheckoutCart />
        </div>
      </div>
    </div>
  );
};

export default Header;
