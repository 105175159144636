import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import Bottle from "../Icons/Bottle";

const SingleProduct = ({
  openCart,
  setOpenCart,
  addItemToCart,
  title,
  smallText,
  subTitle,
  description,
  id,
  featuredImg,
  price,
  productDescription,
  product,
}) => {
  const newProduct = {
    name: title,
    id: id,
    quantity: 1,
    img: featuredImg, // use an img link here
    price,
    productDescription,
    ...product,
  };

  function addClick(oneTime) {
    setOpenCart(!openCart);
    addItemToCart(
      {
        ...newProduct,
        purchaseType: oneTime ? `once` : "subscribe",
      },
      1,
      oneTime
    );
  }

  return (
    <div className="collection-container">
      <div className="section2-left-picture-container">
        <img alt="" loading="lazy" className="section2-left-container-left" effect="blur" src={featuredImg.url} />
        {/* The below is because they asked for this to be hardcoded */}
        {title === "Cellar Collection" ? <div className="limited"> Limited Availabilty</div> : ""}
      </div>
      <div className="section2-bottom-header">
        <h2 className="segoe-font-title wine-collection-title">{title}</h2>
        <p className="segeo-font-title wine-title">{smallText}</p>

        <div className="section-2-paragraph-font section2-bottom-paragraph">{description}</div>
        <div className="section-2-bottom-header-button-wrapper">
          <div className="product-button-wrapper">
            <button className="section-2-bottom-header-button first-purchase-btn" onClick={() => addClick(false)}>
              Purchase Subscription
            </button>
            {id !== "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0Lzc0NDkwNzY5NTc0MjY=" && (
              <button className="section-2-bottom-header-button second-purchase-btn" onClick={() => addClick(true)}>
                One-Time Purchase
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
